// store.js
import { create } from 'zustand';
export const useStore = create(set => ({
  // Modules to include
  modules: JSON.parse(process.env.REACT_APP_MODULES),
  setModules: (array) => set({ modules: array }),      
  // environmental variables
  env: {
    domainName: process.env.REACT_APP_DOMAIN || "https://historyofincarceration.com",
    apiEndpoint: process.env.REACT_APP_DB_API,
    isOnline: process.env.REACT_APP_ONLINE,
    appTitle: process.env.REACT_APP_TITLE,
    kcUrl: process.env.REACT_APP_KC_URL,
    kcRealm: process.env.REACT_APP_KC_REALM,
    kcClient: process.env.REACT_APP_KC_CLIENT
  },
  setEnv: (envvar) => set({ env: envvar }), 
  // CRUD button action trigger 
  buttonAction: {
    "new": false,
    "save": false,
    "revert": false,
    "delete": false 
  },
  setButtonAction: (action) => set({ buttonAction: action }),    
  // Selected row in main window table tab
  selectedRowUUID: null,
  setSelectedRowUUID: (uuid) => set({ selectedRowUUID: uuid }),
// TableDrawer open options  
  tableOpen: false,
  setTableOpen: (bool) => set({ tableOpen: bool }),    
// Get logged in actor info
  activeActor: null,
  setActiveActor: (data) => set({ activeActor: data }),
// States for static lookup data
  popData: false,
  setPopData: (bool) => set({ popData: bool }),  
  tract: [],
  setTract: (data) => set({ tract: data }),
  area: [],
  setArea: (data) => set({ area: data }),  
  scape: [],
  setScape: (data) => set({ scape: data }),
  sample: [],
  setSample: (data) => set({ sample: data }),  
  grid: [],
  setGrid: (data) => set({ grid: data }),
  feat: [],
  setFeat: (data) => set({ feat: data }),   
  line: [],
  setLine: (data) => set({ line: data }),      
  walker: [],
  setWalker: (data) => set({ walker: data }),
  collType: [],
  setCollType: (data) => set({ collType: data }),
  funcArt: [],
  setFuncArt: (data) => set({ funcArt: data }),
  infrMod: [],
  setInfrMod: (data) => set({ infrMod: data }),
  landuse: [],
  setLanduse: (data) => set({ landuse: data }),
  matArt: [],
  setMatArt: (data) => set({ matArt: data }),
  natFeat: [],
  setNatFeat: (data) => set({ natFeat: data }),
  recTypeField: [],
  setRecTypeField: (data) => set({ recTypeField: data }),
  recTypeLab: [],
  setRecTypeLab: (data) => set({ recTypeLab: data }),
  terrain: [],
  setTerrain: (data) => set({ terrain: data }),
  veg: [],
  setVeg: (data) => set({ veg: data }),
  period: [],
  setPeriod: (data) => set({ period: data }),  
  scapeType: [],
  setScapeType: (data) => set({ scapeType: data }),
  featureType: [],
  setFeatureType: (data) => set({ featureType: data }),  
  natResource: [],
  setNatResource: (data) => set({ natResource: data }),    
  depositGeom: [],
  setDepositGeom: (data) => set({ depositGeom: data }),     
  quarryLayout: [],
  setQuarryLayout: (data) => set({ quarryLayout: data }),   
// Static states defined here
  lines: [
      { label: '1', value:1},
      { label: '2', value: 2},
      { label: '3', value: 3},
      { label: '4', value: 4},
      { label: '5', value: 5}
    ],
  setLines: (data) => set({ lines: data }),
// Loaded records
  currTract: null,
  setCurrTract: (data) => set({ currTract: data }),
  currLine: null,
  setCurrLine: (data) => set({ currLine: data }),
  currSegments: [],
  setCurrSegments: (data) => set({ currSegments: data }),    
  currScape: null,
  setCurrScape: (data) => set({ currScape: data }),  
  currFeat: null,
  setCurrFeat: (data) => set({ currFeat: data }),
  currGrid: null,
  setCurrGrid: (data) => set({ currGrid: data }),
  currPhoto: null,
  setCurrPhoto: (data) => set({ currPhoto: data }),
  currFind: null,
  setCurrFind: (data) => set({ currFind: data }),   
  currSample: null,
  setCurrSample: (data) => set({ currSample: data }),      
  // State of the Snackbar 
  snackbarOpen: false,
  setSnackbarOpen: (isOpen) => set({ snackbarOpen: isOpen }),
  snackbarData: {
    "actionType": "",
    "messageType": "",
    "messageText": "" 
  },
  setSnackbarData: (data) => set({ snackbarData: data }),

  //MIMA store (to be separated into a different store)
  userRole: null,
  setUserRole: (data) => set({ userRole: data }),
  currUuid: null,
  setCurrUuid: (data) => set({ currUuid: data }),
  currEntryId: null,
  setCurrEntryId: (data) => set({ currEntryId: data }),     
  termTypes: [],
  setTermTypes: (data) => set({ termTypes: data }),  
  terms: {},
  setTerms: (data) => set({ terms: data }),
  recordLookup: [],
  setRecordLookup: (data) => set({ recordLookup: data }),
  mediaTypes: [],
  setMediaTypes: (data) => set({ mediaTypes: data }), 
  vocabType: 'term',
  setVocabType: (data) => set({ vocabType: data }),  
  places: {},
  setPlaces: (data) => set({ places: data }),
  actors: [],
  setActors: (data) => set({ actors: data }),  
  currActor: null,
  setCurrActor: (data) => set({ currActor: data }),
  currPlace: null,
  setCurrPlace: (data) => set({ currPlace: data }),
  currTerm: null,
  setCurrTerm: (data) => set({ currTerm: data }),    
  currMedia: null,
  setCurrMedia: (data) => set({ currMedia: data }),  
  currDoc: null,
  setCurrDoc: (data) => set({ currDoc: data }),  
  currLit: null,
  setCurrLit: (data) => set({ currLit: data }),  
  currArch: null,
  setCurrArch: (data) => set({ currArch: data }),  
  currViz: null,
  setCurrViz: (data) => set({ currViz: data }),
}));